import { ProdEnvironment } from "./prod.env";
import { DevEnvironment } from "./dev.env";
import { StageEnvironment } from "./staging.env";

export interface Environment {
  base_url: string;
  send_email_url:string;
}

export function getEnvVariable() {
  switch (process.env.NODE_ENV as any) {
    case "production":
      return ProdEnvironment;
    case "test":
      return StageEnvironment;
      case "staging":
      return StageEnvironment;
    default:
      return DevEnvironment;
  }
}
