import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/Authslice";
import registerAppReducer from "./slice/RegisterAppSlice";

export const resetAction = () => ({
  type: "RESET_STATE",
});

export const store = configureStore({
  reducer: {
    user: userReducer,
    registerApp:registerAppReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: true,
    })
      .concat
      (),
});

// Define types for RootState and AppDispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
