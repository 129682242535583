import { useLayoutEffect } from "react";
import { ApiDescriptionProps } from "../../_models/APIDescription";
import "./ApiDescription.scss";
import { Card, CardContent, CardHeader } from "@mui/material";

export const ApiDescription = (props: ApiDescriptionProps) => {
  useLayoutEffect(() => {
    const descriptionElement = document.getElementById("desc");

    if (descriptionElement) {
      descriptionElement.innerHTML = props.description ?? "";
    }
  }, [props.description]);

  return (
    <div className="page-layout api-layout">
      <div className="api-description">
        <h2>{props.header}</h2>
        <p className="description" id="desc"></p>
        <Card className="code-generator">
          <CardHeader title="Based on" />
          <CardContent className="link-txt">
            {props.url
              ? // Split the URL string by <br/> and map over each part
                props.url.split("<br/>").map((url, index) => (
                  <div key={index}>
                    <a
                      href={url.trim()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {url}
                    </a>
                  </div>
                ))
              : "URL not available"}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
