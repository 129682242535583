import { useState } from "react";
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./CardInfoContent.scss";
import { ResponseModel } from "../../_models/ResponseModel";
import React from "react";

interface ResponseInfoProps {
  responses: ResponseModel[];
}

export const ResponseInfo = ({ responses }: ResponseInfoProps) => {
  const [selectedResponse, setSelectedResponse] = useState<number | null>(0);

  const handleItemClick = (index: number) => {
    setSelectedResponse((prevSelected) =>
      index === prevSelected ? null : index
    );
  };

  return (
    <div>
      <Accordion defaultExpanded className="accordian-content">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ m: 0 }}
          className="accordian-header"
          aria-controls="panel1bh-content"
        >
          Responses
        </AccordionSummary>
        <AccordionDetails className="accordian-info">
          <Box>
            <List component="nav" aria-label="main mailbox folders">
              {responses.map((response, index) => (
                <React.Fragment key={"response" + index}>
                  {" "}
                  {/* Add key here */}
                  <ListItemButton
                    className="list-info"
                    alignItems="flex-start"
                    selected={selectedResponse === index}
                    onClick={() => handleItemClick(index)}
                  >
                    <ListItemIcon>
                      <div className={`dot ${response.dotType}`}></div>
                    </ListItemIcon>
                    <ListItemText>
                      <div className="error-no">{response.statusCode}</div>
                      <div className="error-info">{response.description}</div>
                    </ListItemText>
                  </ListItemButton>
                  {index < responses.length - 1 &&
                    selectedResponse !== index &&
                    selectedResponse !== index + 1 && (
                      <Divider component="li" className="custom-divider" />
                    )}
                </React.Fragment>
              ))}
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
