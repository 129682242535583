import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import { DrawerInfo } from "../drawer/Drawer";
import "./Layout.scss";
import { AppBarComponent } from "../topheader/TopHeader";

export const Layout = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBarComponent handleDrawerToggle={handleDrawerToggle} />
        <DrawerInfo closeDrawer={handleCloseDrawer} drawerOpen={drawerOpen} />
        <section className="width-100">
          <Outlet></Outlet>
        </section>
      </Box>
    </>
  );
};
