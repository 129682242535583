import { Typography } from "@mui/material";

const PatientApiAccess = () => {
  return (
    <div className="page-layout api-layout">
      <div className="api-description">
        <h2>Product Overview</h2>
        <p className="description">
          These are the clinical resources that include patient-related
          information, administrative information, or infrastructure components
          used to support the technical exchange of information.
        </p>
        <h2>Product Description</h2>
        <p className="description">
          <Typography sx={{ mb: 2 }}>
            As part of the CMS Interoperability and Patient Access final rule
            (CMS-9115-F), members must have access to any clinical data that a
            payer has readily available. These APIs are required to retrieve
            that information on behalf of a member.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            There are twenty-six (26) clinical resources that conform to the HL7
            FHIR standards. These resources provide the USCDI data elements
            stored by the payer.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Prior to calling this, you must have the proper authentication
            tokens. More details on this API can be found in the Capability
            Statement API.
          </Typography>
          <Typography sx={{ my: 2 }}>
            APIs in this category follow the US Core Implementation Guide v3.1.1
            found at
          </Typography>
          <a
            href="https://build.fhir.org/ig/HL7/davinci-pdex-formulary/artifacts.html#3"
            target="blank"
          >
            https://build.fhir.org/ig/HL7/davinci-pdex-formulary/artifacts.html#3
          </a>
        </p>
      </div>
    </div>
  );
};
export default PatientApiAccess;
