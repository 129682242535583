import React, { useState } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  Box,
  Link,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  InputAdornment,
  IconButton,
  Typography,
  Alert,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { registerUser } from "../../redux/slice/Authslice";
import { Validators } from "../../utils/Validators";
import { useNavigate } from "react-router-dom";
import Loader from "../ui-elements/Loader";
import "./userRegistration.scss";
import logoWhite from "../../images/logo-white.svg";
import companyLogo from "../../images/aaneel-logo.svg";
import logoBlue from "../../images/logo-blue.png";
const UserRegistration: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [formData] = useState({
    userName: "",
    email: "",
    firstName: "",
    lastName: "",
    organizationName: "",
    passwordHash: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [error, setError] = useState("");
  const handleClickShowPassword = (field: string) => {
    if (field === "password") {
      setShowPassword((show) => !show);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword((show) => !show);
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async (formData: any) => {
    setIsLoading(true);
    const { email, firstName, lastName, passwordHash, organizationName } =
      formData;
    const result = await dispatch(
      registerUser({
        userName: email,
        email,
        firstName,
        lastName,
        organizationName: organizationName,
        passwordHash: passwordHash,
      })
    );
    if (result.payload !== undefined || null) {
      setIsLoading(false);
      navigate("/UserLogin");
    } else {
      setError("Email address already used!");
      setIsLoading(false);
    }
    return result;
  };

  return (
    <Box className="register">
      <Box className="product-logo">
        <img src={logoWhite} alt="Logo" />
        <Typography className="version-info">V 0.1</Typography>
      </Box>
      <section className="register-box">
        <label className="back-info">
          <Link href="/userLogin">
            <i className="ac_icons">ac_arrow_left</i>
            <Typography>Back</Typography>
          </Link>
        </label>
        <div>
          <img src={logoBlue} alt="Logo" className="tab-logo" />
          <div className="header-section">
            <h1>Sign up</h1>
            <Box className="signup">
              <div className="info">Already have an account?</div>
              <Link href="/UserLogin">{"Sign in"}</Link>
            </Box>
          </div>

          <Formik
            initialValues={{
              ...formData,
              confirmPassword: "",
            }}
            validateOnMount={true}
            validateOnChange={true}
            validationSchema={Validators.SignupValidator}
            onSubmit={(values) => handleSubmit(values)}
          >
            {(props) => {
              return (
                <Form className="custom-controllers">
                  <FormControl
                    margin="normal"
                    fullWidth
                    variant="filled"
                    error={
                      props.touched.firstName && Boolean(props.errors.firstName)
                    }
                    required
                  >
                    <InputLabel htmlFor="firstName">First Name</InputLabel>
                    <FilledInput
                      id="firstName"
                      name="firstName"
                      value={props.values.firstName}
                      onChange={props.handleChange}
                      onBlur={() => props.setFieldTouched("firstName")}
                      endAdornment={
                        <InputAdornment position="end">
                          <i className="ac_icons">ac_user_line</i>
                        </InputAdornment>
                      }
                    />
                    {props.touched.firstName && props.errors.firstName && (
                      <FormHelperText>{props.errors.firstName}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl
                    margin="normal"
                    fullWidth
                    variant="filled"
                    error={
                      props.touched.lastName && Boolean(props.errors.lastName)
                    }
                    required
                  >
                    <InputLabel htmlFor="lastName">Last Name</InputLabel>
                    <FilledInput
                      id="lastName"
                      name="lastName"
                      value={props.values.lastName}
                      onChange={props.handleChange("lastName")}
                      onBlur={() => props.setFieldTouched("lastName")}
                      endAdornment={
                        <InputAdornment position="end">
                          <i className="ac_icons">ac_user_line</i>
                        </InputAdornment>
                      }
                    />
                    {props.touched.lastName && props.errors.lastName && (
                      <FormHelperText>{props.errors.lastName}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl
                    margin="normal"
                    fullWidth
                    variant="filled"
                    error={props.touched.email && Boolean(props.errors.email)}
                    required
                  >
                    <InputLabel htmlFor="email">Email Address</InputLabel>
                    <FilledInput
                      id="email"
                      name="email"
                      type="email"
                      value={props.values.email}
                      onChange={props.handleChange("email")}
                      onBlur={() => props.setFieldTouched("email")}
                      endAdornment={
                        <InputAdornment position="end">
                          <i className="ac_icons">ac_alternate_email</i>
                        </InputAdornment>
                      }
                    />
                    {props.touched.email && props.errors.email && (
                      <FormHelperText>{props.errors.email}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    margin="normal"
                    fullWidth
                    variant="filled"
                    error={
                      props.touched.organizationName &&
                      Boolean(props.errors.organizationName)
                    }
                    required
                  >
                    <InputLabel htmlFor="organizationName">
                      Organization Name
                    </InputLabel>
                    <FilledInput
                      id="organizationName"
                      name="organizationName"
                      value={props.values.organizationName}
                      onChange={props.handleChange("organizationName")}
                      onBlur={() => props.setFieldTouched("organizationName")}
                      endAdornment={
                        <InputAdornment position="end">
                          <i className="ac_icons">ac_office</i>
                        </InputAdornment>
                      }
                    />
                    {props.touched.organizationName &&
                      props.errors.organizationName && (
                        <FormHelperText>
                          {props.errors.organizationName}
                        </FormHelperText>
                      )}
                  </FormControl>
                  <FormControl
                    margin="normal"
                    fullWidth
                    variant="filled"
                    error={
                      props.touched.passwordHash &&
                      Boolean(props.errors.passwordHash)
                    }
                    required
                  >
                    <InputLabel htmlFor="passwordHash">Password</InputLabel>
                    <FilledInput
                      id="passwordHash"
                      name="passwordHash"
                      type={showPassword ? "text" : "password"}
                      value={props.values.passwordHash}
                      onChange={props.handleChange("passwordHash")}
                      onBlur={() => props.setFieldTouched("passwordHash")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword("password")}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            sx={{
                              position: "relative",
                              right: "4px",
                            }}
                          >
                            {showPassword ? (
                              <i className="ac_icons">ac_eye_off_fill</i>
                            ) : (
                              <i className="ac_icons">ac_eye_fill</i>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {props.touched.passwordHash &&
                      props.errors.passwordHash && (
                        <FormHelperText>
                          {props.errors.passwordHash}
                        </FormHelperText>
                      )}
                  </FormControl>

                  <FormControl
                    margin="normal"
                    fullWidth
                    variant="filled"
                    error={
                      props.touched.confirmPassword &&
                      Boolean(props.errors.confirmPassword)
                    }
                    required
                  >
                    <InputLabel htmlFor="confirmPassword">
                      Confirm Password
                    </InputLabel>
                    <FilledInput
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      value={props.values.confirmPassword}
                      onChange={props.handleChange("confirmPassword")}
                      onBlur={() => props.setFieldTouched("confirmPassword")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword("confirmPassword")
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            sx={{
                              position: "relative",
                              right: "4px",
                            }}
                          >
                            {showConfirmPassword ? (
                              <i className="ac_icons">ac_eye_off_fill</i>
                            ) : (
                              <i className="ac_icons">ac_eye_fill</i>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {props.touched.confirmPassword &&
                      props.errors.confirmPassword && (
                        <FormHelperText>
                          {props.errors.confirmPassword}
                        </FormHelperText>
                      )}
                  </FormControl>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="signup-btn"
                    onClick={() => {
                      if (props.isValid) {
                        props.handleSubmit();
                      }
                    }}
                    disabled={isLoading || !props.isValid}
                  >
                    {isLoading ? <Loader /> : "Sign up"}
                  </Button>
                  {error && (
                    <Alert
                      severity="error"
                      sx={{
                        borderRadius: 4,
                      }}
                    >
                      {error}
                    </Alert>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className="footer-info">
          <div className="content-center">
            <div className="copyright-txt">© 2024 AaNeel Infotech LLC</div>
            <div className="company-logo">
              <div className="pwd-txt">Powered by</div>
              <img src={companyLogo} alt="Logo" />
            </div>
          </div>
        </div>
      </section>
    </Box>
  );
};

export default UserRegistration;
