 import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { CodeBlock } from "react-code-blocks";
import "./DrawerRight.scss";
import { getCodeBlocks, CodeBlocks } from "./CodeBlocks";
import { useParams } from "react-router-dom";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import React from "react";
import { useLocation } from "react-router-dom";
import CommunicareAdvantage from "../../images/communicare.svg";
import secure from "../../images/secure-logo.svg";
import ultimate from "../../images/ultimate-logo.svg";
import { getClientConfigurations } from "../../redux/slice/RegisterAppSlice";
import { AsyncThunkAction, ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
interface PermanentDrawerProps {
  codeurl: string;
  languageurl: string;
  rawHeaders: any;
  activeRoute: string;
}
interface ListItemContent {
  text: string;
  sandboxId: string;
  productionId: string;
  src: string;
}

export const PermanentDrawerRight = (props: PermanentDrawerProps) => {
  const location = useLocation();
  const { route } = location.state || {};
  const [headers, setHeaders]: any = useState(``);
  const [isCopied, setIsCopied] = useState(false);
  const [tooltipText, setTooltipText] = useState("Copy");
  const [tooltipTextCode, setTooltipTextCode] = useState("Copy");
  const [isCopiedCode, setIsCopiedCode] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(
    "ruby"
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const clientConfigurations = useSelector(   // To DO 
    (state: any) => state?.registerApp?.configurations 
  );

  const openMenu = Boolean(anchorEl);
  const [selectedId, setSelectedId] = useState<any>(
    getInitialPayer(route).sandboxId
  );
  const [selectedPayer, setSelectedPayer] = useState<ListItemContent>(
    getInitialPayer(route)
  );
  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
      
  function getInitialPayer(route: string | undefined): ListItemContent {
    if (!route) return clientConfigurations[0];
    switch (true) {
      case route.includes("Health Sun"):
        return clientConfigurations[0];
      case route.includes("Freedom Health Plan"):
        return clientConfigurations[1];
      case route.includes("Optimum Healthcare"):
        return clientConfigurations[2];
      case route.includes("Communicare Advantage"):
        return clientConfigurations[3];
      case route.includes("SECUR"):
        return clientConfigurations[4];
      case route.includes("Ultimate"):
        return clientConfigurations[5];
      default:
      return clientConfigurations[0];
    }
  }

  const { environment } = useParams<{ environment?: string }>();
  const [isProduction, setProduction] = useState<boolean>(
    environment === "prod"
  );
  const handleRadioChange = (id: string) => {
    setSelectedId(id);
    // Find and set the corresponding payer based on the selected id
    const payer = clientConfigurations.find(
      (item : any) => item.sandboxId === id || item.productionId === id
    );
    if (payer) setSelectedPayer(payer);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleHeaders = () => {
    const data: any = props.rawHeaders;
    let strQuery = ``;

    Object.keys(data).forEach((each: string, index: number) => {
      if (data[each]) {
        if (selectedLanguage === "ruby")
          strQuery += ` \n request["${each}"] = "${data[each]}" ${","}`;

        if (selectedLanguage === "java")
          strQuery += `  \n .headers("${each}", "${data[each]}")`;

        if (selectedLanguage === "python")
          strQuery += `  \n "${each}": "${data[each]}"`;
        if (selectedLanguage === "javascript")
          strQuery += `  \n '${each}': '${data[each]}'`;

        if (selectedLanguage === "php")
          strQuery += `  \n "header => ${each}:${data[each]}"`;
      }
    });
    if (selectedLanguage) {
      setHeaders(strQuery);
    }
  };

  useEffect(() => {
    const payer = clientConfigurations.find(
      (item : any) =>
        item.sandboxId === selectedId || item.productionId === selectedId
    );
    setSelectedIndex(isProduction ? 1 : 0);
    if (payer?.productionId || payer?.sandboxId)
      setSelectedId(isProduction ? payer.productionId : payer.sandboxId);
    handleHeaders();
  }, [isProduction]);

  const codeBlocks: CodeBlocks = getCodeBlocks(
    props.codeurl,
    props.languageurl,
    headers
  );

  const languages = [
    {
      label: "Ruby",
      avatarSrc: "/svg/icons8-ruby-programming-language.svg",
      language: "ruby",
    },
    { label: "Python", avatarSrc: "/svg/python.svg", language: "python" },
    { label: "Java", avatarSrc: "/svg/java-original.svg", language: "java" },
    { label: "PHP", avatarSrc: "/svg/php-plain.svg", language: "php" },
    { label: "Node", avatarSrc: "/svg/node-js.svg", language: "javascript" },
    {
      label: "C Sharp",
      avatarSrc: "/svg/c-sharp-14369.svg",
      language: "csharp",
    },
  ];

  const handleLanguageSelect = (language: string) => {
    setSelectedLanguage(language);
    setAnchorEl(null); // Close the menu after selecting a language
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleCopyClick = async (event: React.MouseEvent<any>) => {
    event.stopPropagation();
    setTooltipText("ID Copied");
    setIsCopied(true);
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(selectedId.toLowerCase());
      } catch {}
    }
    setTimeout(() => {
      setTooltipText("Copy");
      setIsCopied(false);
    }, 3000);
  };
  const handleCopyCode = async (event: React.MouseEvent<any>) => {
    event.stopPropagation();
    setTooltipTextCode("Code Copied");
    setIsCopiedCode(true);
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(
          codeBlocks[selectedLanguage || "javascript"]
        );
      } catch {}
    }
    setTimeout(() => {
      setTooltipTextCode("Copy");
      setIsCopiedCode(false);
    }, 3000);
  };
  const [selectedIndex, setSelectedIndex] = useState<number | null>(0);

  const handleButtonClick = (index: number) => {
    setSelectedIndex(index);
    setProduction(index === 1);
    const defaultPayer = clientConfigurations[0];
    // setSelectedPayer(defaultPayer);
  };

  const buttons = ["Sandbox", "Production"];
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        className="right-panel"
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="right"
      >
        <section className="language-select">
          <ButtonGroup
            variant="contained"
            aria-label="Button group"
            className="custom-btn-group"
            fullWidth
          >
            {buttons.map((label, index) => (
              <Button
                key={index}
                fullWidth
                onClick={() => handleButtonClick(index)}
                className={selectedIndex === index ? "selected" : ""}
              >
                {label === "Sandbox" && <i className="ac_icons">ac_union</i>}
                {label === "Production" && (
                  <i className="ac_icons">ac_double_settings</i>
                )}
                {label}
              </Button>
            ))}
          </ButtonGroup>
          <Button
            className="client-info"
            sx={{ p: 0 }}
            id="basic-button"
            aria-controls={openMenu ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            onClick={handleClick}
            disableRipple
          >
            <List sx={{ p: 0 }}>
              <ListItem sx={{ p: 0 }} className="list-box">
                <Box className="content-block">
                  <ListItemAvatar sx={{ p: 0 }}>
                    <Avatar className="avatar">
                      {selectedPayer && (
                        <img src={selectedPayer.src} alt="clientInfo" />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <div className="text-info">
                    <div className="list-text-header">Select Payer</div>
                    {selectedPayer && (
                      <div className="list-text-client">
                        {selectedPayer.text}
                      </div>
                    )}
                    <div className="other-info">
                      {selectedPayer && (
                        <>
                          <div
                            className={
                              selectedIndex === 0 ? "sandbox" : "production"
                            }
                          >
                            {selectedIndex === 0 ? "Sandbox" : "Production"}
                          </div>
                          <Box className="client-id">{selectedId}</Box>
                        </>
                      )}
                    </div>
                  </div>
                </Box>
                <div>
                  <KeyboardArrowDownOutlinedIcon
                    className="arrow-down"
                    style={{
                      transform: openMenu ? "rotate(-180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}
                  />
                </div>
                <Tooltip title={tooltipText} placement="top" arrow>
                  <span>
                    <IconButton
                      aria-label="Copy"
                      onClick={handleCopyClick}
                      disabled={isCopied}
                      className="copy-btn"
                    >
                      <i className="ac_icons">ac_copy</i>
                    </IconButton>
                  </span>
                </Tooltip>
              </ListItem>
            </List>
          </Button>
          <Divider sx={{ mt: 3, mb: 2 }} />
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            className="custom-sandbox"
            onClose={handleClose}
            transformOrigin={{ horizontal: "center", vertical: "top" }}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            <MenuItem className="custom-menu">
              <List
                component="nav"
                aria-label="main mailbox folders"
                className="custom-list"
              >
                {clientConfigurations.map((item :any, index :any) => (
                  <ListItem
                    key={index}
                    className={`custom-list-item ${
                      selectedId === item.sandboxId ||
                      selectedId === item.productionId
                        ? "selected"
                        : ""
                    }`}
                    onClick={() =>
                      handleRadioChange(
                        isProduction ? item.productionId : item.sandboxId
                      )
                    }
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ListItemAvatar sx={{ p: 0 }}>
                        <Avatar className="avatar">
                          <img src={item.src} alt="clientInfo" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText>
                        <div className="avatar-text">{item.text}</div>
                        <Box sx={{ display: "block" }}>
                          <RadioGroup
                            value={selectedId}
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            className="custom-radio"
                          >
                            {!isProduction && (
                              <FormControlLabel
                                value={item.sandboxId}
                                control={<Radio className="hide-radio" />}
                                label={
                                  <Typography variant="body1">
                                    <span className="info-box sandbox">
                                      Sandbox
                                    </span>
                                    <span className="radio-txt">
                                      {item.sandboxId}
                                    </span>
                                  </Typography>
                                }
                              />
                            )}
                            {isProduction && (
                              <FormControlLabel
                                value={item.productionId}
                                control={<Radio className="hide-radio" />}
                                label={
                                  <Typography variant="body1">
                                    <span className="info-box production">
                                      Production
                                    </span>
                                    <span className="radio-txt">
                                      {item.productionId}
                                    </span>
                                  </Typography>
                                }
                              />
                            )}
                          </RadioGroup>
                        </Box>
                      </ListItemText>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </MenuItem>
          </Menu>

          <div className="header-info">Select Language</div>
          <Box
            sx={{ display: "flex", flexDirection: "row" }}
            className="chips-box"
          >
            {languages.slice(0, 6).map((lang, index) => (
              <div key={index} className="chips-section">
                <Chip
                  variant="outlined"
                  color={
                    selectedLanguage === lang.language ? "primary" : "default"
                  }
                  avatar={<Avatar src={lang.avatarSrc} />}
                  label={lang.label}
                  onClick={() => handleLanguageSelect(lang.language)}
                />
                <div className="label-name">{lang.label}</div>
              </div>
            ))}
            {languages.length > 6 && (
              <div className="chips-section">
                <Chip
                  variant="outlined"
                  label="More"
                  color="default"
                  avatar={
                    <Avatar
                      sx={{ transform: "rotate(90deg)" }}
                      src={"/svg/more_horiz_FILL0_wght400_GRAD0_opsz24.svg"}
                    />
                  }
                  onClick={handleMenuOpen}
                />
                <div className="label-name">More</div>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {languages.slice(6).map((lang, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleLanguageSelect(lang.language)}
                    >
                      {lang.label}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            )}
          </Box>
          <Card className="code-generator">
            <CardHeader
              action={
                <Tooltip title={tooltipTextCode} placement="top" arrow>
                  <span>
                    <Button
                      aria-label="Copy"
                      onClick={handleCopyCode}
                      disabled={isCopiedCode}
                      className="copy-btn"
                    >
                      <i className="ac_icons">ac_copy</i>
                      <span>Copy</span>
                    </Button>
                  </span>
                </Tooltip>
              }
              title="Request"
            />
            <CardContent>
              <CodeBlock
                text={codeBlocks[selectedLanguage || "ruby"]}
                language={selectedLanguage || "ruby"}
                showLineNumbers={true}
              />
            </CardContent>
          </Card>
          <p className="info">
            As part of the CMS Interoperability and Patient Access final rule
            (CMS-9115-F), payers must make provider information available. These
            APIs are required to retrieve the provider information.
          </p>
        </section>
      </Drawer>
    </Box>
  );
};


function setIsLoggedIn(arg0: boolean) {
  throw new Error("Function not implemented.");
}

function dispatch(arg0: AsyncThunkAction<any, void, { state?: unknown; dispatch?: ThunkDispatch<unknown, unknown, UnknownAction>; extra?: unknown; rejectValue?: unknown; serializedErrorType?: unknown; pendingMeta?: unknown; fulfilledMeta?: unknown; rejectedMeta?: unknown; }>) {
  throw new Error("Function not implemented.");
}  