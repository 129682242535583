import { useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import "./ApiInfo.scss";
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

interface ApiInfoProps {
  url: string;
  languageurl: string; // Add languageurl prop
}

export const ApiInfo = (props: ApiInfoProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const location = useLocation(); // Get current location
  const [tooltipText, setTooltipText] = useState("Copy");

  const handleCopyClick = async () => {
    setTooltipText("Request Copied");
    try {
      await navigator.clipboard.writeText(props.url);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000); // Reset the copied state after 3 seconds
    } catch (error) {
      console.error("Failed to copy URL: ", error);
    }
    setTimeout(() => {
      setTooltipText("Copy");
      setIsCopied(false);
    }, 3000);
  };

  // Check if the current path matches the specified routes
  const isTokenRoute = location.pathname.includes("/Authorization/Token");
  const isRefreshTokenRoute = location.pathname.includes(
    "/Authorization/RefreshToken"
  );
  const method = isTokenRoute || isRefreshTokenRoute ? "POST" : "GET";

  return (
    <>
      <List
        component="nav"
        aria-label="main mailbox folders"
        className="api-box"
      >
        <ListItemButton>
          <ListItemIcon>
            <div className="get-item">{method}</div>
          </ListItemIcon>
          <ListItemText className="request-box">
            <div className="request-url">
              <div className="request-info">Request</div>
              <div className="request-path">{props.url}</div>
            </div>
          </ListItemText>
          <Tooltip title={tooltipText} placement="top" arrow>
            <span>
              <IconButton
                aria-label="copy"
                size="large"
                className="copy-icon"
                onClick={handleCopyClick}
                disabled={isCopied}
              >
                <i className="ac_icons">ac_copy</i>
              </IconButton>
            </span>
          </Tooltip>
        </ListItemButton>
      </List>
    </>
  );
};
