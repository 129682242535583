import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { DrawerContent } from "./DrawerContent";
import "./Drawer.scss";

export const DrawerInfo = ({ drawerOpen, closeDrawer }: any) => {
  const [isMobileView, setIsMobileView] = React.useState(
    window.innerWidth < 1200
  );
  React.useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 1200;
      setIsMobileView(isMobile);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onDrawerClose = () => {
    closeDrawer();
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Box component="nav" aria-label="mailbox folders">
          <Drawer
            variant={isMobileView ? "temporary" : "permanent"}
            open={drawerOpen}
            className="drawer-section"
            onClose={onDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
              },
            }}
          >
            <DrawerContent />
            <Box className="company-logo">
              <div className="logo-txt">Powered by</div>
              <div className="logo">
                <img src="/svg/aaneel-logo.svg" alt="company logo"></img>
              </div>
            </Box>
          </Drawer>
        </Box>
      </Box>
    </>
  );
};
