import { setUserData } from "../redux/slice/Authslice";
import store from "../redux/store";


export const parseJwt = (token:any) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
};
 export const getUserinfo=()=>{
    // Assuming the JWT is stored in local storage
    const token = localStorage.getItem('userToken');
    if (token) {
      const decodedToken = parseJwt(token);
      store.dispatch(setUserData(decodedToken))
      //console.log("User details are==========>",decodedToken);
    }
}

export const isTokenExpired = () => {
  const token = localStorage.getItem('userToken');
  if (!token) return true;

  const decodedToken = parseJwt(token);
  if (!decodedToken || !decodedToken.exp) return true;

  return Date.now() >= decodedToken.exp * 1000; // Convert exp to milliseconds
};

export const removeToken = () => {
  localStorage.removeItem('userToken');
};


export const formatDate = (dateString:any) => {
  const date = new Date(dateString);
  const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  return formattedDate;
};

export const fetchHtmlTemplate = async (templatePath:string) => {
  try {
    const response = await fetch(templatePath);
    if (response.ok) {
      const html = await response.text();
      return html;
    } else {
      console.error("Failed to fetch the HTML template");
      return null;
    }
  } catch (error) {
    console.error("Error fetching the HTML template:", error);
    return null;
  }
};

export const capitalizeFirstLetter = (string:string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const formatDateWithTime=(date:any)=> {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const dayName = days[date.getDay()];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${dayName}, ${day} ${month} ${year} at ${hours}:${minutes}`;
}

export const decodeBase64URL = (base64URL: any) => {
  // Replace special characters in base64 URL and decode
  const decodedURL = atob(base64URL.replace(/-/g, '+').replace(/_/g, '/'));
  return decodedURL;
};

export const generateDateAndTime = (): string => {
  const currentDateTime: Date = new Date();
  const futureDateTime: Date = new Date(currentDateTime.getTime() + 60 * 60 * 1000);
  const dateFormatted: string = `${(currentDateTime.getMonth() + 1).toString().padStart(2, '0')}/${currentDateTime.getDate().toString().padStart(2, '0')}/${currentDateTime.getFullYear()}`;
  let hour = futureDateTime.getHours();
  const amPM = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12 || 12;
  const timeFormatted: string = `${hour.toString().padStart(2, '0')}:${futureDateTime.getMinutes().toString().padStart(2, '0')} ${amPM}`;

  return `${dateFormatted} ${timeFormatted}`;
};

export function getCurrentDateTime(): string {
  const now = new Date();
  return now.toISOString().split('.')[0] + '.' + now.getMilliseconds().toString().padStart(3, '0');
}
