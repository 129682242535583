import { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CardInfoContent } from "../components/content/CardInfoContent";
import Home from "../components/home/Home";
import PatientAPIAccess from "../components/PatientAPI/PatientAPIAccess";
import { Layout } from "../components/layout/Layout";
import UserRegister from "../components/users/userRegistration";
import UserLogin from "../components/users/userLogin";
import ForgotPassword from "../components/users/forgetPassword";
import ResetPassword from "../components/users/resetPassword";
import { API_DESCRIPTION } from "../_mockData/MockData";
import { ApiDescription } from "../components/api/ApiDescription";
import PatientApiAccess from "../components/menu-content/PatientApiAccess";
import ProviderDirectory from "../components/menu-content/ProviderDirectory";
import Formulary from "../components/menu-content/Formulary";

// const ProfileInfo = lazy(
//   () => import("../components/Secure/pages/profileInfo")
// );
const RegisterNewApplication = lazy(
  () =>
    import(
      "../components/Secure/pages/RegisterNewApplication/RegisterNewApplication"
    )
);
const Dashboard = lazy(
  () => import("../components/Secure/pages/Dashboard/Dashboard")
);
const DetailsPage = lazy(
  () => import("../components/DashboardComponents/DetailsPage")
);
const AuthGuard = lazy(() => import("../service/AuthGuard"));
const Loader = lazy(() => import("../components/ui-elements/Loader"));

const App = () => {
  const publicRoutes = [
    { path: "/home", element: <Home /> },
    { path: "/PatientAPIAccess", element: <PatientAPIAccess /> },
    { path: "/UserLogin", element: <UserLogin /> },
    { path: "/ForgotPassword", element: <ForgotPassword /> },
    { path: "/UserRegister", element: <UserRegister /> },
    { path: "/ResetPassword", element: <ResetPassword /> },
  ];

  const privateRoutes = [
    // { path: "/ProfileInfo", element: <ProfileInfo /> },
    { path: "/RegisterNewApplication", element: <RegisterNewApplication /> },
    { path: "/Dashboard", element: <Dashboard /> },
    { path: "/Details", element: <DetailsPage /> },
    { path: "/providerdirectory/endpoint-api", element: <DetailsPage /> },
  ];

  const cardInfoRoutes = [
    {
      path: "/providerdirectory/location-api/location/:environment?",
      id: "location",
      headerId: "Location",
    },
    {
      path: "/providerdirectory/location-api/locationid/:environment?",
      id: "locationid",
      headerId: "Location",
    },
    {
      path: "/providerdirectory/healthcareService-api/healthcareServiceid/:environment?",
      id: "healthcareid",
      headerId: "Healthcare",
    },
    {
      path: "/providerdirectory/healthcareService-api/healthcareService/:environment?",
      id: "healthcare",
      headerId: "Healthcare",
    },
    {
      path: "/providerdirectory/InsurancePlan-api/insuranceplan/:environment?",
      id: "insurance",
      headerId: "Insurance",
    },
    {
      path: "/providerdirectory/InsurancePlan-api/insuranceplanid/:environment?",
      id: "insuranceid",
      headerId: "Insurance",
    },
    {
      path: "/providerdirectory/network-api/network/:environment?",
      id: "network",
    },
    {
      path: "/providerdirectory/organization-api/organization/:environment?",
      id: "organization",
      headerId: "Organization",
    },
    {
      path: "/providerdirectory/organization-api/organizationid/:environment?",
      id: "organizationid",
      headerId: "Organization",
    },
    {
      path: "/providerdirectory/network-api/networkid/:environment?",
      id: "networkid",
    },
    {
      path: "/providerdirectory/endpoint-api/endpoint/:environment?",
      id: "endpoint",
      headerId: "Endpoint",
    },
    {
      path: "/providerdirectory/endpoint-api/endpointid/:environment?",
      id: "endpointid",
      headerId: "Endpoint",
    },
    {
      path: "/providerdirectory/practitioner-api/practitioner/:environment?",
      id: "Practitioner",
      headerId: "Practitioner",
    },
    {
      path: "/providerdirectory/practitioner-api/practitionerid/:environment?",
      id: "Practitionerid",
      headerId: "Practitioner",
    },
    {
      path: "/providerdirectory/practitionerrole-api/practitionerrole/:environment?",
      id: "PractitionerRole",
      headerId: "PractitionerRole",
    },
    {
      path: "/providerdirectory/practitionerrole-api/practitionerroleid/:environment?",
      id: "PractitionerRoleid",
      headerId: "PractitionerRole",
    },
    {
      path: "/providerdirectory/organizationaffiliation-api/organizationaffiliation/:environment?",
      id: "OrganizationAffiliation",
      headerId: "OrganizationAffiliation",
    },
    {
      path: "/providerdirectory/organizationaffiliation-api/organizationaffiliationid/:environment?",
      id: "OrganizationAffiliationid",
      headerId: "OrganizationAffiliation",
    },
    {
      path: "/PatientAccess/AllergyIntolerance/:environment?",
      id: "AllergyIntolerance",
      headerId: "AllergyIntolerance",
    },
    {
      path: "/PatientAccess/AllergyIntoleranceid/:environment?",
      id: "AllergyIntoleranceid",
      headerId: "AllergyIntolerance",
    },
    {
      path: "/formulary/list-api/list/:environment?",
      id: "list",
      headerId: "List",
    },
    {
      path: "/formulary/list-api/listid/:environment?",
      id: "listid",
      headerId: "List",
    },
    {
      path: "/formulary/medicationknowledge-api/medicationknowledge/:environment?",
      id: "medicationknowledge",
      headerId: "MedicationKnowledge",
    },
    {
      path: "/formulary/medicationknowledge-api/medicationknowledgeid/:environment?",
      id: "medicationknowledgeid",
      headerId: "MedicationKnowledge",
    },
    {
      path: "/formulary/substance-api/substance/:environment?",
      id: "substance",
      headerId: "Substance",
    },
    {
      path: "/formulary/substance-api/substanceid/:environment?",
      id: "substanceid",
      headerId: "Substance",
    },

    {
      path: "/PatientAccess/CarePlan/:environment?",
      id: "CarePlan",
      headerId: "CarePlan",
    },
    {
      path: "/PatientAccess/CareTeam/:environment?",
      id: "CareTeam",
      headerId: "CareTeam",
    },
    {
      path: "/PatientAccess/Condition/:environment?",
      id: "Condition",
      headerId: "Condition",
    },
    {
      path: "/PatientAccess/Coverage/:environment?",
      id: "Coverage",
      headerId: "Coverage",
    },
    {
      path: "/PatientAccess/ExplanationOfBenefit/:environment?",
      id: "ExplanationOfBenefit",
      headerId: "ExplanationOfBenefit",
    },
    {
      path: "/PatientAccess/Goal/:environment?",
      id: "Goal",
      headerId: "Goal",
    },
    {
      path: "/PatientAccess/Immunization/:environment?",
      id: "Immunization",
      headerId: "Immunization",
    },
    {
      path: "/PatientAccess/MedicationRequest/:environment?",
      id: "MedicationRequest",
      headerId: "MedicationRequest",
    },
    {
      path: "/PatientAccess/Observation/:environment?",
      id: "Observation",
      headerId: "Observation",
    },
    {
      path: "/PatientAccess/Patient/:environment?",
      id: "Patient",
      headerId: "Patient",
    },
    {
      path: "/PatientAccess/Procedure/:environment?",
      id: "Procedure",
      headerId: "Procedure",
    },
    { path: "/Payer-to-Payer/everything/:environment?", id: "everything" },
    { path: "/CapabilityStatement/Metadata/:environment?", id: "Metadata" },
    { path: "/Authorization/Authorize/:environment?", id: "Authorize" },
    { path: "/Authorization/Token/:environment?", id: "Token" },
    { path: "/Authorization/RefreshToken/:environment?", id: "RefreshToken" },
  ];

  const getHeaderPath = (id: string) => {
    return API_DESCRIPTION.find((a) => a.id === id);
  };

  return (
    <Router>
      <Routes>
        {/* Redirect from root to home */}
        <Route path="/" element={<Home />} />

        {/* Public Routes */}
        {publicRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}

        {/* Layout with Card Info Routes */}
        <Route path="/" element={<Layout />}>
          <Route path="/providerdirectory" element={<ProviderDirectory />} />
          <Route path="/PatientAccess" element={<PatientApiAccess />} />
          <Route path="/formulary" element={<Formulary />} />

          {cardInfoRoutes.map((route, index) => (
            <>
              <Route
                key={index}
                path={route.path}
                element={<CardInfoContent id={route.id} />}
              />
              {route.headerId && (
                <Route
                  key={index + "child"}
                  path={getHeaderPath(route.headerId ?? "")?.path}
                  element={
                    <ApiDescription
                      url={getHeaderPath(route.headerId ?? "")?.url}
                      description={
                        getHeaderPath(route.headerId ?? "")?.description
                      }
                      header={getHeaderPath(route.headerId ?? "")?.header}
                    />
                  }
                />
              )}
            </>
          ))}
          <Route path="/home" element={<CardInfoContent id="provider" />} />
          {/* Private Routes - Including Dashboard */}
          <Route
            path="/Dashboard"
            element={
              <Suspense fallback={<Loader />}>
                <AuthGuard>
                  <Dashboard />
                </AuthGuard>
              </Suspense>
            }
          />
          {privateRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <Suspense fallback={<Loader />}>
                  <AuthGuard>{route.element}</AuthGuard>
                </Suspense>
              }
            />
          ))}
        </Route>

        {/* Catch all other routes */}
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </Router>
  );
};

export default App;
