import { urls } from "../Urls";
import { Http } from "../Http";
import { AxiosRequestConfig } from "axios";

export class AuthApi {
  static registerUser(data: object) {
    return Http.post(urls.user.createUser,data);
  }

  static loginUser(data: object) {
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const formData = new URLSearchParams();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return Http.post(urls.user.loginUser, formData, config);
  }
  static getUserData(id:any){
    const url = urls.user.getUserData(id)
    return Http.get(url)
  }

  static forgotPasswor(email:any){
    return Http.post(urls.user.ForgotPassword,email)
  }
  static resetPassword(data:object){
    return Http.post(urls.user.resetPassword,data)
  }
}
