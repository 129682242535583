import React from "react";
import AppRouter from "./app-router/AppRouter";
import "./App.css";
import { Provider } from "react-redux";
import store from "./redux/store";
const App: React.FC = () => {

  return(
    <Provider store={store}>
      <AppRouter />
    </Provider>
  )
    
};

export default App;
