export interface CodeBlocks {
  [key: string]: string;
}

export const getCodeBlocks = (
  codeurl: string,
  languageUrl: string,
  headers: string
): CodeBlocks => {
  return {
    javascript: `const https = require('https');
const url = require('url');

const apiUrl = "${codeurl}";

const options = {
  hostname: url.parse(apiUrl).hostname,
  port: url.parse(apiUrl).port,
  path: url.parse(apiUrl).pathname,
  method: 'GET',
  headers: {
    ${headers} 
  }
};

const req = https.request(options, (res) => {
  let data = '';

  res.on('data', (chunk) => {
    data += chunk;
  });

  res.on('end', () => {
    console.log(data);
  });
});

req.end();`,
    java: `import java.net.URI;
import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;

public class Main {
    public static void main(String[] args) throws Exception {
        HttpClient client = HttpClient.newHttpClient();
        HttpRequest request = HttpRequest.newBuilder()
            .uri(URI.create("${codeurl}")) 
             ${headers}
            .build();
        HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());
        System.out.println(response.body());
    }
}`,
    csharp: `using System;
using System.Net.Http;
using System.Threading.Tasks;

class Program
{
    static async Task Main(string[] args)
    {
        using HttpClient client = new HttpClient();
        HttpResponseMessage response = await client.GetAsync("${codeurl}"); 
        response.EnsureSuccessStatusCode();
        string responseBody = await response.Content.ReadAsStringAsync();
        Console.WriteLine(responseBody);
    }
}`,
    ruby: `require 'uri'
require 'net/http'

url = URI("${codeurl}") 

http = Net::HTTP.new(url.host, url.port)
http.use_ssl = true

request = Net::HTTP::Get.new(url)
 ${headers}

response = http.request(request)
puts response.read_body`,
    python: `import requests

url = "${codeurl}" 
headers = {
  ${headers}
}

response = requests.get(url, headers=headers)
print(response.text)`,
    php: `<?php
$url = "${codeurl}"; 
 ${headers}
$opts = [
    "http" => [
        "method" => "GET",
        ${headers}
    ],
];

$context = stream_context_create($opts);
$result = file_get_contents($url, false, $context);
echo $result;
?>`,
    node: `const https = require('https');

const options = {
  hostname: url.parse("${codeurl}").hostname, 
  port: 443,
  path: url.parse("${codeurl}").pathname,
   
  method: 'GET',
  headers: {
    ${headers} 
  }
};

const req = https.request(options, (res) => {
  let data = '';

  res.on('data', (chunk) => {
    data += chunk;
  });

  res.on('end', () => {
    console.log(data);
  });
});

req.end();`,
  };
};
