import { useState } from "react";
import { useParams } from "react-router-dom";
import "./CardInfoContent.scss";
import { ResponseInfo } from "./Response";
import { HeaderInfoContent } from "./Headers";
import { PathInfoContent } from "./Path";
import { QueryInfo } from "./Query";
import { ApiInfo } from "../api/ApiInfo";
import { MOCK_DATA } from "../../_mockData/MockData";
import { PROD_MOCK_DATA } from "../../_mockData/MockDataProd";
import { PermanentDrawerRight } from "../drawer/DrawerRight";
import { useLocation } from "react-router-dom";
import { BodyInfoContent } from "./Body";

interface CardInfoProps {
  id: string;
}

export const CardInfoContent = (props: CardInfoProps) => {
  const location = useLocation();
  const { environment } = useParams<{ environment?: string }>();
  const isProduction = environment === "prod";
  const isTokenRoute = location.pathname.includes("/Authorization/Token");
  const isRefreshTokenRoute = location.pathname.includes(
    "/Authorization/RefreshToken"
  );
  const activeContent = isProduction
    ? PROD_MOCK_DATA[props.id]
    : MOCK_DATA[props.id];
  const activeRoute = props.id ?? "";
  const [query, setQuery] = useState({});
  const [rawHeaders, setRawHeaders] = useState({});

  const handleQuery = (data: any) => {
    let strQuery = ``;
    Object.keys(data).forEach((each: string, index: number) => {
      if (data[each]) {
        strQuery += index === 0 ? "?" : "&";
        strQuery += `${each}=${data[each]}`;
      }
    });
    if (activeRoute) {
      let result: any = {};
      result[activeRoute] = strQuery ?? "";
      setQuery(result);
    }
  };

  const handleHeaders = (data: any) => {
    setRawHeaders(data);
  };

  const handleBody = (data: any) => {
    if (activeRoute) {
      let result: any = {};
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          result[key] = data[key];
        }
      });
    }
  };

  return (
    <section className="page-layout">
      <ApiInfo
        url={activeContent.url}
        languageurl={activeContent.languageurl}
      />
      {activeContent.path?.length > 0 && (
        <PathInfoContent pathinfo={activeContent.path} />
      )}
      <QueryInfo
        queryParams={activeContent.queryParams}
        handleQuery={handleQuery}
      />
      <HeaderInfoContent
        handleHeaders={handleHeaders}
        headerInfo={activeContent.header}
      />
      {(isTokenRoute || isRefreshTokenRoute) && (
        <BodyInfoContent
          bodyInfo={activeContent.info}
          handleBody={handleBody}
        />
      )}
      <ResponseInfo responses={activeContent.response} />
      <PermanentDrawerRight
        activeRoute={activeRoute}
        rawHeaders={rawHeaders}
        languageurl={activeContent.languageurl}
        codeurl={`${activeContent.codeurl}${
          (query as any)?.[activeRoute] ?? ""
        }`}
      />
    </section>
  );
};
