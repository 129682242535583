import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  Box,
  Link,
  IconButton,
  InputAdornment,
  FilledInput,
  FormControl,
  InputLabel,
  FormHelperText,
  Alert,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Validators } from "../../utils/Validators";
import { getUserData, loginUser } from "../../redux/slice/Authslice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import Loader from "../ui-elements/Loader";
import "./userLogin.scss";
import logoWhite from "../../images/logo-white.svg";
import companyLogo from "../../images/aaneel-logo.svg";
import { getUserinfo } from "../../utils/Helper";
import logoBlue from "../../images/logo-blue.png";

const UserLogin: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const userInfo = useSelector((state: any) => state?.user.userData);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (userInfo?.id) {
      dispatch(getUserData(userInfo.id)).then((response) => {
        if (response.payload?.userId) {
          const fetchedFirstName = response.payload?.firstName;
          const fetchedLastName = response.payload?.lastName;
          localStorage.setItem("firstName", fetchedFirstName);
          localStorage.setItem("lastName", fetchedLastName);
          setIsLoading(false);
          navigate("/Dashboard");
        }
      });
    }
  }, [userInfo, dispatch, navigate]);

  const handleSubmit = async (values: {
    username: string;
    password: string;
  }) => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response: any = await dispatch(
        loginUser({
          userName: values.username,
          password: values.password,
        })
      );
      if (response.payload.token) {
        localStorage.setItem("userToken", response.payload.token);
        getUserinfo();
      } else {
        setErrorMessage("Invalid username or password. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Username or Password is incorrect");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="login">
      <Box className="product-logo">
        <img src={logoWhite} alt="Logo" />
        <Typography className="version-info">V 0.1</Typography>
      </Box>

      <section className="login-box">
        <label className="back-info">
          <Link href="/home">
            <i className="ac_icons">ac_arrow_left</i>
            <Typography>Back</Typography>
          </Link>
        </label>
        <div>
          <img src={logoBlue} alt="Logo" className="tab-logo" />
          <div className="header-section">
            <h1>Sign in</h1>
            <Box className="signup">
              <div className="info"> Don't have an account?</div>
              <Link href="/UserRegister">{"Sign Up"}</Link>
            </Box>
          </div>
          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={Validators.LoginValidator}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form className="custom-controllers">
                <FormControl
                  margin="normal"
                  fullWidth
                  variant="filled"
                  error={
                    props.touched.username && Boolean(props.errors.username)
                  }
                  required
                >
                  <InputLabel htmlFor="username">Email Address</InputLabel>
                  <FilledInput
                    id="username"
                    name="username"
                    autoFocus
                    autoComplete="off"
                    value={props.values.username}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <i className="ac_icons">ac_alternate_email</i>
                      </InputAdornment>
                    }
                  />
                  {props.touched.username && props.errors.username && (
                    <FormHelperText>{props.errors.username}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  variant="filled"
                  fullWidth
                  error={
                    props.touched.password && Boolean(props.errors.password)
                  }
                  required
                >
                  <InputLabel>Password</InputLabel>
                  <FilledInput
                    id="password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    autoComplete="off"
                    value={props.values.password}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{
                            position: "relative",
                            right: "4px",
                          }}
                        >
                          {showPassword ? (
                            <i className="ac_icons">ac_eye_off_fill</i>
                          ) : (
                            <i className="ac_icons">ac_eye_fill</i>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {props.touched.password && props.errors.password && (
                    <FormHelperText>{props.errors.password}</FormHelperText>
                  )}
                </FormControl>

                <Button
                  type="submit"
                  className="login-btn"
                  fullWidth
                  variant="contained"
                  disableElevation
                  sx={{ mt: 3, mb: 3 }}
                  disabled={!props.isValid || props.isSubmitting}
                >
                  {isLoading ? <Loader /> : "Sign in"}
                </Button>
                <Box className="forgot-link">
                  <Link href="/ForgotPassword" variant="body2">
                    Forgot password?
                  </Link>
                </Box>
                {errorMessage && (
                  <Alert
                    severity="error"
                    sx={{
                      borderRadius: 4,
                    }}
                  >
                    {errorMessage}
                  </Alert>
                )}
              </Form>
            )}
          </Formik>
        </div>
        <div className="footer-info">
          <div className="content-center">
            <div className="copyright-txt">© 2024 AaNeel Infotech LLC</div>
            <div className="company-logo">
              <div className="pwd-txt">Powered by</div>
              <img src={companyLogo} alt="Logo" />
            </div>
          </div>
        </div>
      </section>
    </Box>
  );
};

export default UserLogin;
