import axios, { AxiosRequestConfig } from "axios";
import { getEnvVariable } from "../environments/env";

export class EmailService {
  private static axios = axios.create({
    baseURL: getEnvVariable().send_email_url,
  });

  static async post(url: string, body?: FormData, config?: AxiosRequestConfig) {
    try {
      const token = localStorage.getItem("userToken");
      const headers: AxiosRequestConfig["headers"] = token ? { authorization: token } : {};
  
      // Set content type to form-data if body is FormData
      if (body instanceof FormData) {
        headers["Content-Type"] = "multipart/form-data";
      }
  
      const updatedConfig: AxiosRequestConfig = {
        ...config,
        headers: { ...config?.headers, ...headers },
      };
  
      const response = await EmailService.axios.post(url, body, updatedConfig);
      return response.data;
    } catch (e) {
      EmailService.handleErrors(e);
      return Promise.reject(e);
    }
  }
  
  private static handleErrors(error: any) {
    if (error.response) {
          const errorMessage =
        error.response.data?.message ||
        "Something Went Wrong. Please Try Again";
    }
  }
}
