import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loader=()=> {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress 
       size={40}
       thickness={4}
     
       value={100}
      />
    </Box>
  );
}

export default Loader