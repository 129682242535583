import { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Grid,
  IconButton,
  InputAdornment,
  FilledInput,
  FormControl,
  InputLabel,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import "./CardInfoContent.scss";
import { HeaderInfoModel } from "../../_models/HeaderInfoModel";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { getUserData } from "../../redux/slice/Authslice";

interface HeaderInfoProps {
  headerInfo: HeaderInfoModel[] | undefined;
  handleHeaders: (payload: any) => void;
}

export const HeaderInfoContent = ({
  headerInfo,
  handleHeaders,
}: HeaderInfoProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedIndex, setSelectedIndex] = useState<string | null>(
    headerInfo?.length && headerInfo[0].label ? headerInfo[0].label : null
  );
  const userInfo = useSelector((state: any) => state?.user.userData);
  const defaultValues: any = headerInfo?.reduce((acc, curr) => {
    acc[curr.label] = ""; // Initialize with empty string
    return acc;
  }, {} as { [key: string]: string });
  // Initialize inputValues with default values for all labels
  const [inputValues, setInputValues] = useState<{
    [key: string]: string;
  }>(defaultValues);

  const handleChange = (label: string, value: string) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [label]: value,
    }));
  };

  const handleListItemClick = (label: string | null) => {
    setSelectedIndex(label);
  };

  const handleClear = (label: string) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [label]: "",
    }));
  };

  useEffect(() => {
    const placeholderValues: any = headerInfo?.reduce((acc, curr) => {
      acc[curr.label] = curr.placeholder; // Initialize with empty string
      return acc;
    }, {} as { [key: string]: string });
    if (inputValues) {
      let result = { ...inputValues };
      Object.keys(result).forEach((each: string, index: number) => {
        result[each] =
          inputValues[each] === ""
            ? placeholderValues?.[each]
            : inputValues[each];
      });
      handleHeaders(result);
    }
  }, [inputValues, headerInfo, handleHeaders]);

  useEffect(() => {
    if (userInfo?.id) {
      dispatch(getUserData(userInfo.id));
    }
  }, [userInfo, dispatch]);

  return headerInfo?.length ? (
    <div>
      <Accordion defaultExpanded className="accordian-content">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ m: 0 }}
          className="accordian-header"
          aria-controls="panel1bh-content"
        >
          Headers
        </AccordionSummary>
        <AccordionDetails className="accordian-info">
          <Box>
            <List component="nav" aria-label="main mailbox folders">
              {headerInfo?.map((header, index) => (
                <ListItem key={header.label + index} disablePadding>
                  <ListItemButton
                    className="list-info"
                    disableRipple
                    selected={selectedIndex === header.label}
                    onClick={() => handleListItemClick(header.label)}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <div className="text-header-width">
                          <div className="header-txt">
                            {header.label}
                            {header.label === "Authorization" && (
                              <span className="required"> *</span>
                            )}
                            <span className="string-info">String</span>
                          </div>
                          <div className="sub-content">
                            {header.info}
                            {""}
                            <span className="bold-txt">{header.infobold}</span>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        display="flex"
                        justifyContent="end"
                        className="custom-controllers"
                      >
                        <FormControl fullWidth variant="filled">
                          <InputLabel
                            htmlFor={`input-with-no-floating-label${header.label}`}
                          >
                            {header.label}
                          </InputLabel>
                          <FilledInput
                            id={`input-with-no-floating-label${header.label}`}
                            placeholder={header.placeholder}
                            value={inputValues[header.label] || ""}
                            onChange={(event) =>
                              handleChange(header.label, event.target.value)
                            }
                            autoComplete="off"
                            // InputLabelProps should be applied on FormControl or TextField if you're using them
                            // InputLabelProps={{ shrink: false }} // This prop is not required here
                            endAdornment={
                              inputValues[header.label] && (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="clear input"
                                    onClick={() => handleClear(header.label)}
                                    edge="end"
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  ) : (
    <></>
  );
};
