import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getEnvVariable } from "../environments/env";

export class Http {
  private static axios = axios.create({
    baseURL: getEnvVariable().base_url,
    headers: {
      "Content-Type": "application/json",
    },
  });

  static async get(url: string, config?: AxiosRequestConfig) {
    try {
      const token = localStorage.getItem("userToken");
      const headers = token ? { authorization: `Bearer ${token}` } : {};
      const updatedConfig: AxiosRequestConfig = { ...config, headers };
      const response = await Http.axios.get(url, updatedConfig);
      return response.data;
    } catch (e) {
      return Http.handleErrors(e);
    }
  }

  static async post(url: string, body?: object, config?: AxiosRequestConfig) {
    try {
      const token = localStorage.getItem("userToken");
      const headers: AxiosRequestConfig["headers"] = token ? { authorization: `Bearer ${token}` } : {};

      // Set headers based on content type
      const contentType = config?.headers?.["Content-Type"];
      if (contentType === "application/x-www-form-urlencoded") {
        headers["Content-Type"] = "application/x-www-form-urlencoded";
      } else {
        headers["Content-Type"] = "application/json";
      }

      const updatedConfig: AxiosRequestConfig = {
        ...config,
        headers: { ...config?.headers, ...headers },
      };

      const response = await Http.axios.post(url, body, updatedConfig);
      return response.data;
    } catch (e) {
      return Http.handleErrors(e);
    }
  }

  static async put(url: string, body?: object, config?: AxiosRequestConfig) {
    try {
      const token = localStorage.getItem("userToken");
      const headers: AxiosRequestConfig["headers"] = token ? { authorization: `Bearer ${token}` } : {};

      // Set headers based on content type
      const contentType = config?.headers?.["Content-Type"];
      if (contentType === "application/x-www-form-urlencoded") {
        headers["Content-Type"] = "application/x-www-form-urlencoded";
      } else {
        headers["Content-Type"] = "application/json";
      }

      const updatedConfig: AxiosRequestConfig = {
        ...config,
        headers: { ...config?.headers, ...headers },
      };

      const response = await Http.axios.put(url, body, updatedConfig);
      return response.data;
    } catch (e) {
      return Http.handleErrors(e);
    }
  }

  static uploadImage = async (file: File): Promise<string> => {
    const token = localStorage.getItem("userToken");
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response: AxiosResponse<any> = await axios.post(
        "https://aaneelconnect-api.azurewebsites.net/Upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const imageUrl: string = response.data;
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };
  static async postWithJson(url: string, body?: object, config?: AxiosRequestConfig) {
    try {
      const token = await localStorage.getItem("userToken");
      const headers: AxiosRequestConfig["headers"] = token ? { authorization: `Bearer ${token}` } : {};
      const updatedConfig: any = { ...config, headers: headers };
      const response = await Http.axios.post(
        url,
        body,
        updatedConfig,
      );
      if (response) {
        return response;
      }
    } catch (e) {
      Http.handleErrors(e);
      return Promise.reject(e);
    }
  }

  private static handleErrors(error: any) {
    if (error.response) {
      const errorMessage =
        error.response||
        "Something Went Wrong. Please Try Again";
        console.log(errorMessage)
  
    } else {
      console.log("Something went wrong. Please try again.");
    }
  }
}
