import {
  createSlice,
  createAsyncThunk,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { AuthApi } from "../../service/api/AuthApi";

const initialState = {
  isLoading: false,
  error: null as string | null,
  userData: null,
  headerInfo:null,
};

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (userData: object) => {
    try {
      const response = await AuthApi.registerUser(userData);      
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const loginUser = createAsyncThunk("user/loginUser", async (data:object) => {
  try {
   
    const response = await AuthApi.loginUser(data);
    return response;
  } catch (error) {
    return error;
  }
});

export const getUserData = createAsyncThunk("user/getUserData",async (id:any)=>{
  try{
   const response = await AuthApi.getUserData(id);
   return response
  }catch(error){
    return error
  }
})

export const forgotPassword = createAsyncThunk("user/forgotPassword", async (data:object) => {
  try {
   
    const response = await AuthApi.forgotPasswor(data);
    return response;
  } catch (error) {
    return error;
  }
});

export const resetPassword = createAsyncThunk("user/resetPassword", async (data:object) => {
  try {
   
    const response = await AuthApi.resetPassword(data);
    return response;
  } catch (error) {
    return error;
  }
});
const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload; // Update user data in the store
      sessionStorage.setItem("userInformation",action.payload)
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.headerInfo = action.payload;
      })
      .addMatcher(isPending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? null;
      });
  },
});
export const { setUserData } = authSlice.actions;
export default authSlice.reducer;
