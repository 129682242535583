export const LeftPanelDataProd = [
  {
    parent: "Provider Directory",
    primary: "Providerdirectory ",
    openState: false,
    id: "",
    path: "/providerdirectory",
    secondpath: "",
    children: [
      {
        primarynested: "Endpoint API",
        openState: false,
        id: "Endpoint/{id}",
        subiteminfo: "Endpoint",
        path: "/providerdirectory/endpoint-api/endpoint/prod",
        secondpath: "/providerdirectory/endpoint-api/endpointid/prod",
      },
      {
        primarynested: "Healthcare Service API",
        openState: false,
        id: "HealthcareService/{id}",
        subiteminfo: "Healthcare",
        path: "/providerdirectory/healthcareService-api/healthcareService/prod",
        secondpath:
          "/providerdirectory/healthcareService-api/healthcareServiceid/prod",
      },
      {
        primarynested: "Insurance Plan API",
        openState: false,
        subiteminfo: "Insurance",
        id: "InsurancePlan/{id}",
        path: "/providerdirectory/insuranceplan-api/insuranceplan/prod",
        secondpath: "/providerdirectory/insuranceplan-api/insuranceplanid/prod",
      },
      {
        primarynested: "Location API",
        openState: false,
        subiteminfo: "Location",
        id: "Location/{id}",
        path: "/providerdirectory/location-api/location/prod",
        secondpath: "/providerdirectory/location-api/locationid/prod",
      },
      // {
      //   primarynested: "Network API",
      //   openState: false,
      //   subiteminfo: "Network",
      //   id: "Network/{id}",
      //   path: "/providerdirectory/network-api/network/prod",
      //   secondpath: "/providerdirectory/network-api/networkid/prod",
      // },
      {
        primarynested: "Organization API",
        openState: false,
        subiteminfo: "Organization",
        id: "Organization/{id}",
        path: "/providerdirectory/organization-api/organization/prod",
        secondpath: "/providerdirectory/organization-api/organizationid/prod",
      },
      {
        primarynested: "Organization Affiliation API",
        openState: false,
        subiteminfo: "OrganizationAffiliation",
        id: "OrganizationAffiliation/{id}",
        path: "/providerdirectory/organizationaffiliation-api/organizationaffiliation/prod",
        secondpath:
          "/providerdirectory/organizationaffiliation-api/organizationaffiliationid/prod",
      },
      {
        primarynested: "Practitioner API",
        openState: false,
        subiteminfo: "Practitioner",
        id: "Practitioner/{id}",
        path: "/providerdirectory/practitioner-api/practitioner/prod",
        secondpath: "/providerdirectory/practitioner-api/practitionerid/prod",
      },
      {
        primarynested: "Practitioner Role API",
        openState: false,
        subiteminfo: "PractitionerRole",
        id: "PractitionerRole/{id}",
        path: "/providerdirectory/practitionerrole-api/practitionerrole/prod",
        secondpath:
          "/providerdirectory/practitionerrole-api/practitionerroleid/prod",
      },
    ],
  },
  {
    parent: "Formulary",
    primary: "Formulary",
    openState: false,
    id: "",
    path: "/formulary",
    secondpath: "",
    children: [
      {
        primarynested: "List API",
        openState: false,
        subiteminfo: "List",
        id: "list/{id}",
        path: "/formulary/list-api/list/prod",
        secondpath: "/formulary/list-api/listid/prod",
      },
      {
        primarynested: "Substance API",
        openState: false,
        subiteminfo: "Substance",
        id: "Substance/{id}",
        path: "/formulary/substance-api/substance/prod",
        secondpath: "/formulary/substance-api/substanceid/prod",
      },
      {
        primarynested: "Medication Knowledge API",
        openState: false,
        subiteminfo: "MedicationKnowledge",
        id: "MedicationKnowledge/{id}",
        path: "/formulary/medicationknowledge-api/medicationknowledge/prod",
        secondpath:
          "/formulary/medicationknowledge-api/medicationknowledgeid/prod",
      },
    ],
  },
  {
    parent: "Patient API Access",
    primary: "Patient API Access",
    openState: false,
    id: "",
    path: "/PatientAccess",
    secondpath: "",
    children: [
      {
        primarynested: "AllergyIntolerance",
        openState: false,
        subiteminfo: "AllergyIntolerance",
        id: "AllergyIntolerance/{id}",
        path: "/PatientAccess/AllergyIntolerance/prod",
        secondpath: "/PatientAccess/AllergyIntoleranceid/prod",
      },
      {
        primarynested: "CarePlan",
        openState: false,
        subiteminfo: "CarePlan",
        id: "CarePlan",
        path: "/PatientAccess/CarePlan/prod",
        secondpath: "",
      },
      {
        primarynested: "CareTeam",
        openState: false,
        subiteminfo: "CareTeam",
        id: "CareTeam",
        path: "/PatientAccess/CareTeam/prod",
        secondpath: "",
      },
      {
        primarynested: "Condition",
        openState: false,
        subiteminfo: "Condition",
        id: "Condition",
        path: "/PatientAccess/Condition/prod",
        secondpath: "",
      },
      {
        primarynested: "Coverage",
        openState: false,
        subiteminfo: "Coverage",
        id: "Coverage",
        path: "/PatientAccess/Coverage/prod",
        secondpath: "",
      },
      {
        primarynested: "Explanation Of Benefit",
        openState: false,
        subiteminfo: "ExplanationOfBenefit",
        id: "ExplanationOfBenefit",
        path: "/PatientAccess/ExplanationOfBenefit/prod",
        secondpath: "",
      },
      {
        primarynested: "Goal",
        openState: false,
        subiteminfo: "Goal",
        id: "Goal",
        path: "/PatientAccess/Goal/prod",
        secondpath: "",
      },
      {
        primarynested: "Immunization",
        openState: false,
        subiteminfo: "Immunization",
        id: "Immunization",
        path: "/PatientAccess/Immunization/prod",
        secondpath: "",
      },
      {
        primarynested: "MedicationRequest",
        openState: false,
        subiteminfo: "MedicationRequest",
        id: "MedicationRequest",
        path: "/PatientAccess/MedicationRequest/prod",
        secondpath: "",
      },
      {
        primarynested: "Observation",
        openState: false,
        subiteminfo: "Observation",
        id: "Observation",
        path: "/PatientAccess/Observation/prod",
        secondpath: "",
      },
      {
        primarynested: "Patient",
        openState: false,
        subiteminfo: "Patient",
        id: "Patient",
        path: "/PatientAccess/Patient/prod",
        secondpath: "",
      },
      {
        primarynested: "Procedure",
        openState: false,
        subiteminfo: "Procedure",
        id: "Procedure",
        path: "/PatientAccess/Procedure/prod",
        secondpath: "",
      },
    ],
  },
  {
    parent: "Payer to Payer API",
    primary: "Payer-to-Payer",
    openState: false,
    id: "",
    path: "",
    secondpath: "",
    children: [
      {
        primarynested: "$everything",
        openState: false,
        subiteminfo: "$everything",
        id: "everything",
        path: "/Payer-to-Payer/everything/prod",
        secondpath: "",
      },
    ],
  },
  {
    parent: "Capability Statement",
    primary: "CapabilityStatement",
    openState: false,
    id: "",
    path: "",
    secondpath: "",
    children: [
      {
        primarynested: "Metadata",
        openState: false,
        subiteminfo: "Metadata",
        id: "Metadata",
        path: "/CapabilityStatement/Metadata/prod",
        secondpath: "",
      },
    ],
  },
  {
    parent: "Authorization",
    primary: "Authorization",
    openState: false,
    id: "",
    path: "",
    secondpath: "",
    children: [
      {
        primarynested: "Authorize",
        openState: false,
        subiteminfo: "Authorize",
        id: "Authorize",
        path: "/Authorization/Authorize/prod",
        secondpath: "",
      },
      {
        primarynested: "Token",
        openState: false,
        subiteminfo: "Token",
        id: "Token",
        path: "/Authorization/Token/prod",
        secondpath: "",
      },
      {
        primarynested: "Refresh Token",
        openState: false,
        subiteminfo: "RefreshToken",
        id: "RefreshToken",
        path: "/Authorization/RefreshToken/prod",
        secondpath: "",
      },
    ],
  },
];
