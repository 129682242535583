import { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItemButton,
  Grid,
  IconButton,
  InputAdornment,
  FilledInput,
  FormControl,
  InputLabel,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import "./CardInfoContent.scss";
import { BodyInfoModel } from "../../_models/BodyInfoModel";

interface BodyInfoProps {
  bodyInfo: BodyInfoModel[] | undefined;
  handleBody: (payload: any) => void;
}

export const BodyInfoContent = ({ bodyInfo, handleBody }: BodyInfoProps) => {
  const [selectedBody, setSelectedBody] = useState<string | null>(
    bodyInfo?.length && bodyInfo[0].label ? bodyInfo[0].label : null
  );

  // Ensure all input values are defined initially
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    // Initialize input values with default or empty strings
    const initialValues: { [key: string]: string } = {};
    bodyInfo?.forEach((info) => {
      initialValues[info.label] = info.value || ""; // Use empty string if value is undefined
    });
    setInputValues(initialValues);
  }, [bodyInfo]);

  useEffect(() => {
    handleBody(inputValues);
  }, [inputValues,handleBody]);

  const handleChange = (label: string, value: string) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [label]: value,
    }));
  };

  const handleListItemClick = (label: string | null) => {
    setSelectedBody(label);
  };

  const handleClear = (label: string) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [label]: "",
    }));
  };

  return bodyInfo?.length ? (
    <div>
      <Accordion defaultExpanded className="accordian-content">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ m: 0 }}
          className="accordian-header"
          aria-controls="panel1bh-content"
        >
          Body
        </AccordionSummary>
        <AccordionDetails className="accordian-info">
          <Box>
            <List component="nav" aria-label="main mailbox folders">
              {bodyInfo?.map((info, index) => (
                <ListItemButton
                  key={info.label + index}
                  disableRipple
                  className="list-info"
                  selected={selectedBody === info.label}
                  onClick={() => handleListItemClick(info.label)}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <div className="text-width">
                        <div className="header-txt">
                          {info.label}
                          {[
                            "grant_type",
                            "redirect_uri",
                            "code",
                            "refresh_token",
                          ].includes(info.label) && (
                            <span className="required">*</span>
                          )}
                          <span className="string-info">String</span>
                        </div>
                        <div className="sub-content">{info.placeholder}</div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      display="flex"
                      justifyContent="end"
                      className="custom-controllers"
                    >
                      <FormControl fullWidth variant="filled">
                        <InputLabel
                          htmlFor={`input-with-no-floating-label${info.label}`}
                        >
                          {info.label}
                        </InputLabel>
                        <FilledInput
                          id={`input-with-no-floating-label${info.label}`}
                          placeholder={info.placeholder}
                          value={inputValues[info.label] || ""}
                          onChange={(event) =>
                            handleChange(info.label, event.target.value)
                          }
                          autoComplete="off"
                          endAdornment={
                            inputValues[info.label] && (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="clear input"
                                  onClick={() => handleClear(info.label)}
                                  edge="end"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </ListItemButton>
              ))}
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  ) : (
    <></>
  );
};
