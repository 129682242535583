import { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItemButton,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  FilledInput,
  FormControl,
  InputLabel,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import "./CardInfoContent.scss";
import { QueryParamModel } from "../../_models/QueryParamModel";

interface QueryInfoProps {
  queryParams: QueryParamModel[];
  handleQuery: (payload: any) => void;
}

export const QueryInfo = ({ queryParams, handleQuery }: QueryInfoProps) => {
  const [selectedQuery, setSelectedQuery] = useState<string | null>(
    queryParams.length > 0 ? queryParams[0].label : null
  );

  // Ensure all input values are defined initially
  const [inputValues, setInputValues] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    // Initialize input values with default or empty strings
    const initialValues: { [key: string]: string } = {};
    queryParams.forEach((param) => {
      initialValues[param.label] = param.value || ""; // Use empty string if value is undefined
    });
    setInputValues(initialValues);
  }, [queryParams]);

  const handleChange = (label: string, value: string) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [label]: value,
    }));
  };

  useEffect(() => {
    handleQuery(inputValues);
  }, [inputValues, handleQuery]);

  const handleListItemClick = (label: string | null) => {
    setSelectedQuery(label);
  };

  const handleClear = (label: string) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [label]: "",
    }));
  };

  return (
    <div>
      <Accordion defaultExpanded className="accordian-content">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ m: 0 }}
          className="accordian-header"
          aria-controls="panel1bh-content"
        >
          Query Params
        </AccordionSummary>
        <AccordionDetails className="accordian-info">
          <Box>
            <List component="nav" aria-label="main mailbox folders">
              {queryParams.map((param, index) => (
                <ListItemButton
                  key={param.label + index}
                  disableRipple
                  className="list-info"
                  selected={selectedQuery === param.label}
                  onClick={() => handleListItemClick(param.label)}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <div className="text-width">
                        <div className="header-txt">
                          {param.label}{" "}
                          {[
                            "payer-id",
                            "client_id",
                            "redirect_uri",
                            "response_type",
                            "scope",
                            "state",
                          ].includes(param.label) && (
                            <span className="required">*</span>
                          )}
                          <span className="string-info">String</span>
                        </div>
                        <div className="sub-content">
                          {param.info}
                          <span className="bold-txt">{param.infobold}</span>
                        </div>
                        {param.chains && (
                          <>
                            <div className="chains">
                              Chains:{" "}
                              <span className="blue-txt">(_contained=true</span>{" "}
                              is required)
                            </div>
                            <Stack direction="row" flexWrap="wrap" spacing={1}>
                              {param.chipsname.map((chipsname, ci) => (
                                <div
                                  className="chips-box"
                                  key={param.label + index + ci}
                                >
                                  <div className="chips-dot"></div>
                                  <div className="chips-txt">{chipsname}</div>
                                </div>
                              ))}
                            </Stack>
                          </>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      display="flex"
                      justifyContent="end"
                      className="custom-controllers"
                    >
                      <FormControl fullWidth variant="filled">
                        <InputLabel
                          htmlFor={`input-with-no-floating-label${param.label}`}
                        >
                          {param.label}
                        </InputLabel>
                        <FilledInput
                          id={`input-with-no-floating-label${param.label}`}
                          placeholder={param.placeholder}
                          value={inputValues[param.label] || ""}
                          onChange={(event) =>
                            handleChange(param.label, event.target.value)
                          }
                          autoComplete="off"
                          // InputLabelProps should be applied on FormControl or TextField if you're using them
                          // InputLabelProps={{ shrink: false }} // This prop is not required here
                          endAdornment={
                            inputValues[param.label] && (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="clear input"
                                  onClick={() => handleClear(param.label)}
                                  edge="end"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </ListItemButton>
              ))}
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
